import { createAction, props } from "@ngrx/store";
import { ReadyGGLeaderboardData, ReadyGGLeaderboardEntry } from "@readygg/ng-api";

export const getLeaderboardEntries = createAction(
    '[Leaderboards] Get Leaderboard Entries',
    props<{ id: string, isAuthenticated: boolean }>()
);

export const getLeaderboardEntriesSuccess = createAction(
    '[Leaderboards] Get Leaderboard Entries Success',
    props<{ entries: ReadyGGLeaderboardEntry[] | undefined }>()
);

export const getLeaderboardEntriesFailure = createAction(
    '[Leaderboards] Get Leaderboard Entries Failure',
    props<{ error: any }>()
);

export const getUserEntry = createAction(
    '[Leaderboards] Get User Entry',
);

export const getUserEntrySuccess = createAction(
    '[Leaderboards] Get User Entry Success',
    props<{ entry: ReadyGGLeaderboardEntry }>()
);

export const getUserEntryFailure = createAction(
    '[Leaderboards] Get User Entry Failure',
    props<{ error: any }>()
);
export const getLeaderboardsByAppIds = createAction(
    '[Leaderboards] Get By AppIds'
);

export const getLeaderboardsByAppIdsSuccess = createAction(
    '[Leaderboards] Get By AppIds Success',
    props<{ leaderboards: ReadyGGLeaderboardData[] | undefined }>()
);

export const getLeaderboardsByAppIdsFailure = createAction(
    '[Leaderboards] Get By AppIds Failure',
    props<{ error: any }>()
);

export const getLeaderboardById = createAction(
    '[Leaderboards] Get By Id',
    props<{ id: string }>()
);

export const getLeaderboardByIdSuccess = createAction(
    '[Leaderboards] Get By Id Success',
    props<{ activeLeaderboard: ReadyGGLeaderboardData | undefined }>()
);

export const getLeaderboardByIdFailure = createAction(
    '[Leaderboards] Get By Id Failure',
    props<{ error: any }>()
);

export const clearEntries = createAction(
    '[Leaderboards] Clear Entries'
);


export const LeaderboardsActions = {
    getLeaderboardEntries,
    getLeaderboardEntriesSuccess,
    getLeaderboardEntriesFailure,
    getUserEntry,
    getUserEntrySuccess,
    getUserEntryFailure,
    getLeaderboardsByAppIds,
    getLeaderboardsByAppIdsSuccess,
    getLeaderboardsByAppIdsFailure,
    getLeaderboardById,
    getLeaderboardByIdSuccess,
    getLeaderboardByIdFailure,
    clearEntries
};
